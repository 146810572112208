<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
            <Header
                v-bind="data"
                type="small"
            />
        </WaveDownBG>
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDown.vue';
import Header from '@/components/Header.vue';

export default {
    name: 'State',
    components: {
        WaveDownBG,
        Header,
    },
    computed: {
        data() {
            switch (this.$route.name) {
            case 'Error404':
                return {
                    title: this.$t('general.404_title'),
                    text: this.$t('general.404_text'),
                    actions: [{
                        title: this.$t('general.home'),
                        to: '/',
                        class: 'shadow-m bg-accent mb-l',
                    }],
                };

            case 'DonateError':
                return {
                    title: this.$t('donate.error.title'),
                    text: this.$t('donate.error.text'),
                    actions: [{
                        title: this.$t('donate.menu'),
                        to: '/donate',
                        class: 'shadow-m bg-accent mb-l',
                    }],
                };

            case 'DonateSuccess': {
                const data = this.$store.getters.data;
                return {
                    title: data.donate_success_title_en,
                    text: data.donate_success_text_en,
                    image: this.cdnurl(data.donate_success_image),
                    actions: [{
                        title: data.donate_success_button_title_en,
                        to: data.donate_success_button_link,
                        class: 'shadow-m bg-accent mb-l',
                    }],
                };
            }

            default:
                return {}
            }
        },
    },
};
</script>
